/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@3.4.1/dist/css/bootstrap.min.css
 * - /npm/bootstrap-datepicker@1.9.0/dist/css/bootstrap-datepicker3.min.css
 * - /npm/bootstrap-slider@9.2.0/dist/css/bootstrap-slider.min.css
 * - /npm/nprogress@0.2.0/nprogress.min.css
 * - /npm/slick-carousel@1.8.1/slick/slick.min.css
 * - /npm/magnific-popup@1.1.0/dist/magnific-popup.min.css
 * - /npm/bootstrap-select@1.13.18/dist/css/bootstrap-select.min.css
 * - /npm/toastr@2.1.4/build/toastr.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
